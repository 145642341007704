<template>
    <div class="four-up page-layout">
        <div class="slot">
            <slot name="slot1" />
        </div>
        <div class="slot">
            <slot name="slot2" />
        </div>
        <div class="slot">
            <slot name="slot3" />
        </div>
        <div class="slot">
            <slot name="slot4" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'FourUpLayout'
};
</script>

<style lang="scss" scoped>
.four-up {
    display: grid;
    row-gap: 0%;
    grid-template-columns: 48% 48%;
    column-gap: 2%;
    .slot {
        width: 100%;
        padding: 10px;
    }
}
</style>